<template>
  <div class="box">
    <el-form :model="form">
      <div class="title">货品标签价设置</div>
       <el-form-item>
        <el-radio-group v-model="form.switch_params" @change="handleChange">
          <div class="item">
            <el-radio :label="1">不自动四舍五入</el-radio>
          </div>
          <div class="item">
            <el-radio :label="2">四舍五入</el-radio>
          </div>
          <div class="item">
            <el-radio :label="3">自定义</el-radio>
          </div>
        </el-radio-group>
        <div class="item" v-if="show">
          <span style="color: #F56C6C;">*</span>按角位，逢<el-input style="width: 50px; padding: 0 10px;" @input="handleInput" v-model="form.ext_params" />进十
        </div>
      </el-form-item>
      <div class="title">整单抹零设置</div>
        <el-form-item>
          <el-radio-group v-model="form.ml_set">
            <div class="item">
              <el-radio :label="1">不自动抹零</el-radio>
            </div>
            <div class="item">
              <el-radio :label="2">自动抹零(有小数点自动抹除)</el-radio>
            </div>
          </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addReq, getCashSetReq } from '@/api/system/settleConfig.js';

export default {
  data() {
    return {
      flag: false, // 为true时不能提交
      form: {
        switch_params: 1,
        ml_set: 2,
      },
      show: false,
    };
  },
  created() {
    getCashSetReq()
      .then((res) => {
        if (res.code === 1) {
          this.form = res.data;
          if (res.data.switch_params === 3) {
            this.show = true;
          } else {
            this.show = false;
          }
        }
      });
  },
  methods: {
    handleSave() {
      if (this.flag) {
        this.$message.warning('请输入1-9的正整数');
        return;
      }
      addReq({
        modules: 'set_cash',
        switch_name: 'saleprice_4s5r',
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('操作成功');
          }
        });
    },
    handleInput(e) {
      const reg = /^[1-9]{1}$/;
      const result = reg.test(e);
      if (!result) {
        this.flag = true;
        this.$message.warning('请输入1-9的正整数');
      } else {
        this.flag = false;
      }
    },
    handleChange(e) {
      if (e === 3) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  padding: 20px 40px;
  background: white;
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .item {
    line-height: 30px;
    display: flex;
    align-items: center;
    height: 30px;
  }
}
</style>
